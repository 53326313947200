import React, { useState, useEffect } from "react";
import "../styles/Sidebar.scss";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar({DenyAuth}) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <div className="sidebar">
      <div className="innerlink">
        <h2>Smart City Nilanga</h2>

        <div className="links">
          <Link to="/" className={activeLink === "/" ? "active" : ""}>
            Dashboard
          </Link>
          <Link
            to="/contacts"
            className={activeLink === "/contacts" ? "active" : ""}
          >
            All Enquires
          </Link>
          {/* <Link
            to="/form"
            className={activeLink === "/form" ? "active" : ""}
          >
            Contact Form
          </Link> */}
          <Link style={{userSelect : "none"}}
            to="/upload-blog"
            className={activeLink === "/upload-blog" ? "active" : ""}
          >
            Upload News
          </Link>
          <Link
            to="/allblogs"
            className={activeLink === "/allblogs" ? "active" : ""}
          >
            All News
          </Link>
          {activeLink === "/update" && <Link
            to="/update"
            className={activeLink === "/update" ? "active" : ""}
          >
            Update Entry
          </Link>}
          
          {activeLink === "/updateblog" && <Link
            to="/updateblog"
            className={activeLink === "/updateblog" ? "active" : ""}
          >
            Update Blog
          </Link>}
        </div>
      </div>

      <button className="logout" onClick={DenyAuth }>Logout</button>
    </div>
  );
}
