import { Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import Dashbaord from './pages/Dashbaord';
import { Route } from 'react-router-dom';
import Login from './pages/Login';
import "./App.scss"
import { useState } from 'react';
import Sidebar from './comps/Sidebar';
import ContactsList from './pages/ContactsList';
import ContactForm from './pages/ContactForm';
import UpdateContact from './pages/UpdateContact';
import UploadBlog from './pages/UploadBlog';
import BlogList from './pages/BlogList';
import UpdateBlog from './pages/UpdateBlog';
import BlogTemp from './comps/BlogTemp';


function App() {
  const [authState, setAuthState] = useState(false);
  
  function grantAuth() {
    setAuthState(true);
  }
  function DenyAuth() {
    setAuthState(false);
    
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Sidebar DenyAuth={DenyAuth} />
        <Routes>
          {authState ? (
            <Route path="/" element={<Dashbaord />} />
          ) : (
            <Route path="/*" element={<Login  grantAuth={grantAuth}   />} />
          )}
          {authState ? (
            <Route path="/contacts" element={<ContactsList />} />
          ) : (
            <Route path="/*" element={<Login  grantAuth={grantAuth}   />} />
          )}
          {authState ? (
            <Route path="/form" element={<ContactForm />} />
          ) : (
            <Route path="/*" element={<Login  grantAuth={grantAuth}   />} />
          )}
          {authState ? (
            <Route path="/update" element={<UpdateContact />} />
          ) : (
            <Route path="/*" element={<Login  grantAuth={grantAuth}   />} />
          )}
          {authState ? (
            <Route path="/upload-blog" element={<UploadBlog />} />
          ) : (
            <Route path="/*" element={<Login  grantAuth={grantAuth}   />} />
          )}
          {authState ? (
            <Route path="/allblogs" element={<BlogList />} />
          ) : (
            <Route path="/*" element={<Login  grantAuth={grantAuth}   />} />
          )}
          {authState ? (
            <Route path="/updateblog" element={<UpdateBlog />} />
          ) : (
            <Route path="/*" element={<Login  grantAuth={grantAuth}   />} />
          )}
       
          <Route path="/blog/temp/:id" element={<BlogTemp  />} />
          
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
