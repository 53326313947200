import React, { useState } from "react";
import { getDatabase, ref, update } from "firebase/database";
import { app } from "../Firebase";
import Header from "../comps/Header";
import { notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export default function UpdateContact() {
  const location = useLocation();
  const navigate = useNavigate();

  const [name, setname] = useState(location.state[1].Client_Name);
  const [address, setaddress] = useState(location.state[1].Client_Address);
  const [email, setemail] = useState(location.state[1].Client_Email);
  const [phone, setphone] = useState(location.state[1].Client_Phone);
  const [buying, setbuying] = useState(location.state[1].Interested_In);
  const [query, setquery] = useState(location.state[1].Client_Message);
  const [api, contextHolder] = notification.useNotification();

  const handleSubmit = (event) => {
    event.preventDefault();

    const db = getDatabase(app);
    const contactsRef = ref(db, `Contacts/${location.state[0]}`); // Reference to the existing entry

    update(contactsRef, {
      Client_Name: name,
      Client_Address: address,
      Client_Phone: phone,
      Client_Email: email,
      Client_Message: query,
      Interested_In: buying,
    });

    const openNotificationWithIcon = () => {
      api.success({
        message: "Update Successful !",
        description: "redirecting to contacts page",
      });
    };
    openNotificationWithIcon();

    navigate("/contacts");
  };

  return (
    <>
      {contextHolder}
      <div className="mainarea">
        <Header text="Update Info" />

        <form action="#" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="name"
            value={name}
            onChange={(e) => setname(e.target.value)}
          />
          <input
            className="form-input"
            onChange={(e) => setaddress(e.target.value)}
            name="address"
            value={address}
          />
          <input
            type="text"
            placeholder="phone"
            value={phone}
            onChange={(e) => setphone(e.target.value)}
          />
          <input
            type="text"
            placeholder="email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
          <select
            className="form-input"
            onChange={(e) => setbuying(e.target.value)}
            name="buying"
            value={buying}

          >
            <option value="Residential">
              Residentail
            </option>
            <option value="Commercial">
              Commercial
            </option>
            <option value="Both Residential and Commercial">Both Residential and Commercial</option>
            <option value="Others">Others</option>
          </select>

          <textarea
            cols="30"
            rows="10"
            value={query}
            placeholder="Comments"
            onChange={(e) => setquery(e.target.value)}
          ></textarea>
          <button type="submit" className="btn">
            Update Info
          </button>
        </form>
      </div>
    </>
  );
}
