import "../styles/BlogTemp.scss"
import React, { useEffect, useState } from "react";

import { getDatabase, ref, onValue } from "firebase/database";
import { app } from "../Firebase";
import { useParams } from "react-router-dom";


function BlogTemp() {
  const [blogData, setBlogData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const db = getDatabase(app);
    const blogRef = ref(db, `Blogs/${id}`);
    
    // Retrieve data and calculate total number of entries
    onValue(blogRef, (snapshot) => {
      const data = snapshot.val();
      setBlogData(data);
    });
  }, [id]);













  return (
    <div className='blogtemp'>
      <div className="content-width">
       <div className="topbox">
        <div className="imgbox"></div>
        <div className="detailsbox">

          <h2>
            {blogData.Title}
          </h2>

          <p>
          {blogData.Content}
          </p>
        </div>
       </div>
      </div>
    </div>
  )
}

export default BlogTemp
