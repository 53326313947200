import React, { useEffect, useState } from "react";
import Header from "../comps/Header";
import { MdModeEdit, MdDelete, MdOutlineArrowUpward } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {
  getDatabase,
  onValue,
  ref as databaseRef,
  remove,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { app } from "../Firebase";

function BlogList() {
  const [blogData, setBlogData] = useState(null);
  const [visibleBlogs, setVisiblBlogs] = useState(5);
  const [filters, setFilters] = useState({
    category: "all",
    language: "all",
    title: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase(app);
    const blogRef = databaseRef(db, "Blogs");

    onValue(blogRef, (snapshot) => {
      const data = snapshot.val();
      setBlogData(data);
    });
  }, []);

  const deleteData = (key, imgUrl) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Blog?"
    );

    if (confirmDelete) {
      const db = getDatabase(app);
      const blogRef = databaseRef(db, `Blogs/${key}`);

      remove(blogRef);

      const storage = getStorage(app);
      const imageRef = storageRef(storage, `images/${key}`);

      deleteObject(imageRef)
        .then(() => {
          // console.log("Image deleted successfully");
        })
        .catch((error) => {
          // console.error("Error deleting image:", error);
        });
    }
  };

  const loadMore = () => {
    setVisiblBlogs((prevVisibleBlogs) => prevVisibleBlogs + 10);
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const filteredBlogs = Object.entries(blogData || {}).filter(
    ([key, value]) =>
      (filters.category === "all" || value.Category === filters.category) &&
      (filters.language === "all" || value.Language === filters.language) &&
      value.Title.toLowerCase().includes(filters.title.toLowerCase())
  );

  return (
    <div className="mainarea">
      <Header text="All News" id="top" />
      <a href="#top" className={showButton ? "top" : "top inv"}>
        <MdOutlineArrowUpward />
      </a>

      {blogData ? (
        <>
          {/* <div className="filter">
            <input
              type="text"
              placeholder="Search blog here ..."
              name="title"
              value={filters.title}
              onChange={handleFilterChange}
            />
            <select
              name="category"
              value={filters.category}
              onChange={handleFilterChange}
            >
              <option value="all">All</option>
              {Array.from(
                new Set(Object.values(blogData).map((value) => value.Category))
              ).map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <select
              name="language"
              value={filters.language}
              onChange={handleFilterChange}
            >
              <option value="all">All</option>
              
            </select>

            <button className="btn" onClick={()=> setFilters({category: "all",
    language: "all",
    title: "",})}>Reset</button>
          </div> */}
          {filteredBlogs.length === 0 ? (
          <p>No results found</p>
        ) : (
          <div className="tablebox">
            <table>
              <thead>
                <tr>
                  <th style={{width : "150px"}} >Feature Image</th>
                  <th style={{width: "150px"}}  >Date</th>
                  <th>Title</th>
                  <th>Content</th>
                  <th style={{width: "150px"}} >Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredBlogs
                  .slice(0, visibleBlogs)
                  .map(([key, value]) => (
                    <tr key={key}>
                      <td>
                        <div
                          className="blog-img bg-img-cover"
                          style={{ background: `url(${value.Img})` }}
                        ></div>
                      </td>
                      <td>
                        {value.Title.slice(0, 10)}
                        {value.Title.length > 60 && <span>...</span>}
                      </td>
                     
                      <td>{value.Date}</td>
                      <td>{value.Content.slice(0, 100)}</td>
                      <td className="actions">
                        <button
                          onClick={() =>
                            navigate("/updateblog", { state: [key, value] })
                          }
                        >
                          <MdModeEdit />
                        </button>
                        <button onClick={() => deleteData(key, value.Img)}>
                          <MdDelete />
                        </button>
                        <Link to={`/blog/temp/${value.Id}`}>view more</Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {filteredBlogs.length > visibleBlogs && (
              <div className="btn-box">
                <button className="btn" onClick={loadMore}>
                  Load More
                </button>
              </div>
            )}
          </div>
 )}
        </>
      ) : (
        <p>No data found till now</p>
      )}
      
    </div>
  );
}

export default BlogList;
