import React, { useState, useEffect } from "react";
import { getDatabase, ref, set, push, onValue } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { app } from "../Firebase";
import Header from "../comps/Header";
import { notification } from "antd";
import "../styles/UploadBlog.scss";
import { useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
function UploadBlog() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [date, setDate] = useState("");
  const [img, setImg] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [blogsData, setBlogsData] = useState([]);
  console.log(blogsData);

  const handleUpload = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check file size (limit to 3MB)
      if (file.size > 2 * 1024 * 1024) {
        alert("File size exceeds 2 MB. Please select a smaller file.");
        return;
      }

      // Check file type
      const allowedFileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/webp",
      ];
      if (!allowedFileTypes.includes(file.type)) {
        alert(
          "Invalid file type. Please select a valid image file (JPEG, JPG, PNG, WebP)."
        );
        return;
      }

      // File is valid, set it in the state
      setImg(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!img) {
      alert("Please select a valid feature image");
      return;
      // or set an error state and display it in the UI
      // setError("Please select an image");
      // return;
    }

    const db = getDatabase(app);
    const storage = getStorage(app);

    const blogsRef = ref(db, "Blogs");
    const newblogRef = push(blogsRef);

    // Generate a unique key for the image storage location
    const imageStorageKey = newblogRef.key;
    const myRef = storageRef(storage, `images/${imageStorageKey}`);

    await uploadBytes(myRef, img);

    const imgUrl = await getDownloadURL(myRef);

    {
      const blogEntry = {
        Id: newblogRef.key,
        Title: title,
        Date: date,
        Img: imgUrl,
        Content: ckdata,
      };

      set(newblogRef, blogEntry);

      const openNotificationWithIcon = () => {
        api.success({
          message: "Blog Uploaded",
          description: "",
        });
      };
      openNotificationWithIcon();

      setTitle("");
      setContent("");
      setImg(null);

      navigate("/allblogs");
    }
  };



  useEffect(() => {
    const db = getDatabase(app);
    const contactRef = ref(db, "Blogs");
    // Retrieve data and calculate total number of entries
    onValue(contactRef, (snapshot) => {
      const data = snapshot.val();
      setBlogsData(data);
      // console.log(data)
    });
  }, []);
  const [ckdata, setckdata] = useState('')
  const handleCKEditorChange = (event, editor) => {
    const data = editor.getData();
    setckdata(data);
  };
  console.log(ckdata)
  return (
    <>
      {contextHolder}
      <div className="mainarea">
        <Header text="Post Latest News" />

        <form className="upload" action="#" onSubmit={handleSubmit}>
          <label > News Feature Image</label>
          <input type="file" className="img" onChange={handleUpload} />
          <label > News Title</label>
          <input
            type="text"
            placeholder="News Title"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <label > News Upload Date</label>
          <input
            type="date"

            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="date"
          />


          <label > News Content</label>
          {/* <textarea
            placeholder="Content goes here"
            cols="30"
            rows="10"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          ></textarea> */}
          {/* ckeditor */}
          <CKEditor
            editor={ClassicEditor}
            data={ckdata} // Setting initial data
            onChange={handleCKEditorChange}
            onBlur={(event, editor) => {
              // console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              // console.log('Focus.', editor);
            }}
          />
          {/* ckeditor */}

          <button
            type="submit"
            style={{ width: "fit-content" }}
            className="btn"
          >
            Post
          </button>
        </form>
      </div>
    </>
  );
}

export default UploadBlog;
